import { useEffect, useState } from 'react'
import './app.scss'
import axios from 'axios'
import Slide from '@mui/material/Slide'
import IG from './images/instagram.png'
import bca from './images/bca.png'
import AOS from 'aos'
import "aos/dist/aos.css"
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import EventIcon from '@mui/icons-material/Event'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import MusicNoteIcon from '@mui/icons-material/MusicNote'
import MusicOffIcon from '@mui/icons-material/MusicOff'
// import PhotoAlbum from "react-photo-album"
// import Lightbox from "yet-another-react-lightbox"
import "yet-another-react-lightbox/styles.css"
// import photo1 from './images/photo1.jpg'
// import photo2 from './images/photo2.jpg'
// import photo3 from './images/photo3.jpg'
// import photo4 from './images/s.jpg'
// import photo5 from './images/opening.jpg'
// import photo6 from './images/photo4.jpg'
// import photo7 from './images/photo5.jpg'
// import Timeline from '@mui/lab/Timeline'
// import TimelineItem from '@mui/lab/TimelineItem'
// import TimelineSeparator from '@mui/lab/TimelineSeparator'
// import TimelineConnector from '@mui/lab/TimelineConnector'
// import TimelineContent from '@mui/lab/TimelineContent'
// import TimelineDot from '@mui/lab/TimelineDot'
import TextField from '@mui/material/TextField'
import music from './music/TheWayYouLookAtMe.mp3'

// import optional lightbox plugins
// import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen"
// import Slideshow from "yet-another-react-lightbox/plugins/slideshow"
// import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails"
// import Zoom from "yet-another-react-lightbox/plugins/zoom"
import "yet-another-react-lightbox/plugins/thumbnails.css"

function App() {
  const [open, setOpen] = useState(true)
  const [index, setIndex] = useState(-1)
  const [copy, setCopy] = useState(false)
  const [copy2, setCopy2] = useState(false)
  const [musics, setMusics] = useState(false)
  const [name, setName] = useState('')
  const [text, setText] = useState('')
  const [data, setData] = useState([])
  const [render, setRender] = useState(false)

  useEffect(() => {
    AOS.init({
      duration: 1200
    })
    AOS.refresh()

    axios.get('https://invitation.ffkno.my.id/all-msg-wedding')
      .then(({ data }) => {
        setData(data)
      }).catch(err => {
        console.log(err);
      })

    setRender(false)
  }, [render])

  const getNamePerson = () => {
    const getName = window.location.search.replace('?to=', '').replace('-', ' ').replace('-', ' ').replace('-', ' ')
    return getName
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    axios.post('https://invitation.ffkno.my.id/msg-wedding', { name: name, message: text })
      .then(({ data }) => {
        console.log(data);
        setRender(true)
        setName('')
        setText('')
      }).catch(err => {
        console.log(err);
      })


  }

  const handleOpen = () => {
    setOpen(false)
    window.scrollTo(0, 0)

    setMusics(true);
    document.getElementById('song').play();

    // Set the date we're counting down to
    const countDownDate = new Date("Dec 07, 2024 09:00:00").getTime()

    // Update the count down every 1 second
    let x = setInterval(function () {

      // Get today's date and time
      let now = new Date().getTime()

      // Find the distance between now and the count down date
      let distance = countDownDate - now

      // Time calculations for days, hours, minutes and seconds
      let days = Math.floor(distance / (1000 * 60 * 60 * 24))
      let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      let seconds = Math.floor((distance % (1000 * 60)) / 1000)

      // Output the result in an element with id="time"
      document.getElementById("time").innerHTML = days
      document.getElementById("time2").innerHTML = hours
      document.getElementById("time3").innerHTML = minutes
      document.getElementById("time4").innerHTML = seconds


      // If the count down is over, write some text 
      if (distance < 0) {
        clearInterval(x)
        document.getElementById("time").innerHTML = "0"
        document.getElementById("time2").innerHTML = "0"
        document.getElementById("time3").innerHTML = "0"
        document.getElementById("time4").innerHTML = "0"
      }
    }, 1000)
  }

  const onMusic = () => {
    setMusics(!musics);
    if (musics === false) {
      document.getElementById('song').play();
    }
    if (musics === true) {
      document.getElementById('song').pause();
    }
  }

  const copyText = (e) => {
    // Copy the text inside the text field
    navigator.clipboard.writeText(e)

    // Alert the copied text
    e === '005801128097502' ? setCopy(true) : setCopy2(true)

    setTimeout(() => {
      setCopy(false)
      setCopy2(false)
    }, 500)
  }

  const handleDate = (e) => {
    const d = new Date(e)
    const date = d.getDate()
    const month = d.getMonth() + 1
    const year = d.getFullYear()

    return (date < 10 ? '0' + date : date) + '.' + (month < 10 ? '0' + month : month) + '.' + year
  }

  // const photos = [
  //   { src: photo1, width: 600, height: 600 },
  //   { src: photo2, width: 1600, height: 900 },
  //   { src: photo3, width: 1000, height: 1200 },
  //   { src: photo4, width: 1000, height: 1200 },
  //   { src: photo5, width: 1000, height: 1200 },
  //   { src: photo6, width: 1000, height: 1200 },
  //   { src: photo7, width: 1000, height: 1200 },
  // ]

  return (
    <main>
      <div className='music' onClick={() => onMusic()}>
        <audio id='song' loop>
          <source src={music} />
        </audio>
        {musics && <MusicNoteIcon style={{ color: 'white' }} />}
        {!musics && <MusicOffIcon style={{ color: 'white' }} />}
      </div>
      <Slide in={open} {...(open ? {} : { timeout: 1500 })}>
        <div className="container-opening">
          <div className='bg-flower-intro'></div>
          <div className='section-opening'>
            <p>Wedding Invitation</p>
            <h1>Eka & Lukman</h1>
            <p>07.12.2024</p>
            <div className='section-bottom'>
              <p>Kepada Yth. Bapak/Ibu/Saudara/i :</p>
              <p>{getNamePerson()}</p>
              <button onClick={handleOpen}>Buka Undangan</button>
            </div>
          </div>
        </div>
      </Slide>
      <div className='container-fix'>
        <div className='border-bg'></div>
        <p>The Wedding of</p>
        <h1>Eka & Lukman</h1>
        <p>07.12.2024</p>
      </div>
      <div className='container-slide'>
        <div className='shape-book'></div>
        <div className='container-intro'>
          <div className='bg-flower'></div>
          <h2>The Beloved</h2>
          <h1>Groom & Bride</h1>
          <div className='section-doa'>
            <p className='text-arab'>بسم الله الرحمن الرحيم</p>
            <p className='text-arab'>وَمِنْ ءَايَٰتِهِۦٓ أَنْ خَلَقَ لَكُم مِّنْ أَنفُسِكُمْ أَزْوَٰجًا لِّتَسْكُنُوٓا۟ إِلَيْهَا وَجَعَلَ بَيْنَكُم مَّوَدَّةً وَرَحْمَةً ۚ إِنَّ فِى ذَٰلِكَ لَءَايَٰتٍ لِّقَوْمٍ يَتَفَكَّرُونَ</p>
            <p>"Dan Diantara tanda-tanda kebesaran-Nya ialah diciptakan-Nya untukmu pasangan hidup dari jenismu sendiri supaya kamu mendapatkan ketenangan hati dan dijadikan-Nya kasih sayang diantara kamu sesungguhnya yang demikian menjadi tanda-tanda kebesaran-Nya bagi orang-orang yang berfikir"</p>
          </div>
          <div className='container-groom-bride'>
            <div className='container-groom'>
              <div className='container-photo'>
                <div className='photo-groom' data-aos="fade-up"></div>
              </div>
              <h1 data-aos="fade-up">Eka Novia Sari</h1>
              <p data-aos="fade-up">Putri Dari</p>
              <p data-aos="fade-up">Bpk. Kliwon & Ibu. Kasiani</p>
              {/* <a href='https://www.instagram.com/doni.hermanto12/?igshid=MWZjMTM2ODFkZg%3D%3D' target='blank' data-aos="fade-up" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: '#282a3a', marginTop: '10px' }}><img src={IG} alt="instagram" width={20} /> <span style={{ marginLeft: '5px' }}>doni.hermanto12</span>  </a> */}
            </div>
            <h1 data-aos="fade-up">&</h1>
            <div className='container-groom'>
              <div className='container-photo'>
                <div className='bg-photo'></div>
                <div className='photo-bride' data-aos="fade-up"></div>
              </div>
              <h1 data-aos="fade-up">Mhd Lukmanul Hakim</h1>
              <p data-aos="fade-up">Putra Dari</p>
              <p data-aos="fade-up">Bpk. Abdul Haris & Ibu. Julianita Lubis</p>
              {/* <a href='https://instagram.com/awkaraen?igshid=MzRlODBiNWFlZA==' target='blank' data-aos="fade-up" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: '#282a3a', marginTop: '10px' }}><img src={IG} alt="instagram" width={20} /> <span style={{ marginLeft: '5px', paddingBottom: '3px' }}>awkaraen</span>  </a> */}
            </div>
          </div>
        </div>
        <div className='container-time'>
          <h1 data-aos="fade-down">Save The Date</h1>
          <div data-aos="fade-down" className='countdown'>
            <div className='times'>
              <p id="time"></p>
              <p>Days</p>
            </div>
            <div className='times'>
              <p id="time2"></p>
              <p>Hours</p>
            </div>
            <div className='times'>
              <p id="time3"></p>
              <p>Minutes</p>
            </div>
            <div className='times'>
              <p id="time4"></p>
              <p>Seconds</p>
            </div>
          </div>
        </div>
        <div className='container-place'>
          <div className='bg-flower-btm'></div>
          <div className='bg-flower-top'></div>
          <div className='section-event'>
            <h1 data-aos="fade-down">Resepsi</h1>
            {/* <p data-aos="fade-down">Rumah Mempelai Wanita</p> */}
            <p data-aos="fade-down"> <span><EventIcon /></span> Sabtu, 07 Desember 2024</p>
            <p data-aos="fade-down"> <span><AccessTimeIcon /></span> Pukul 09.00 WIB s/d Selesai</p>
            <p data-aos="fade-down"> <span><LocationOnIcon /></span> Bertempat di Jl. Bersama Gang Terong</p>
            <p data-aos="fade-down"> <span></span> Kec. Medan Tembung</p>
            <p data-aos="fade-down"> <span></span> Kota. Medan</p>
            <a data-aos="fade-down" href='https://maps.app.goo.gl/mqScRF41Wy7PcsiC7' target='blank'>Lihat Lokasi</a>
          </div>

        </div>
        {/* <div className='container-gallery' data-aos="zoom-in-up">
          <h1>- Gallery -</h1>
          <PhotoAlbum photos={photos} layout="rows" targetRowHeight={200} onClick={({ index }) => setIndex(index)} />
          <Lightbox
            slides={photos}
            open={index >= 0}
            index={index}
            close={() => setIndex(-1)}
            // enable optional lightbox plugins
            plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
          />
        </div> */}
        {/* <div className='container-love-story' data-aos="fade-up">
          <h1>- Love Story -</h1>
          <Timeline position="alternate" >
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot style={{ backgroundColor: '#c69749' }} />
                <TimelineConnector style={{ backgroundColor: '#c69749' }} />
              </TimelineSeparator>
              <TimelineContent>
                <p style={{ fontSize: '18px', fontWeight: 500, marginBottom: '10px' }}>Awal 2018</p>
                <p style={{ marginBottom: '50px' }}>Berawal dari diperkenalkan oleh sahabat kami</p>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot style={{ backgroundColor: '#c69749' }} />
                <TimelineConnector style={{ backgroundColor: '#c69749' }} />
              </TimelineSeparator>
              <TimelineContent>
                <p style={{ fontSize: '18px', fontWeight: 500, marginBottom: '10px' }}>Juni 2021</p>
                <p style={{ marginBottom: '50px' }}>Setelah beberapa tahun tidak berkomunikasi, akhirnya kami bertemu lagi untuk jalan-jalan bersama sahabat kami. Suatu hari kami terkena Covid-19 dan kami harus menjalankan karantina di rumah masing-masing, dan kami sering menghabiskan waktu dengan bercerita via VC</p>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot style={{ backgroundColor: '#c69749' }} />
                <TimelineConnector style={{ backgroundColor: '#c69749' }} />
              </TimelineSeparator>
              <TimelineContent>
                <p style={{ fontSize: '18px', fontWeight: 500, marginBottom: '10px' }}>Oktober 2022</p>
                <p style={{ marginBottom: '10px' }}>Seiring berjalannya waktu tanpa berpacaran, kami memutuskan untuk berkomitmen dan berniat untuk melanjutkan ke jenjang yang lebih serius di tahun 2023</p>
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        </div> */}
        <div className='container-wedding-gift' >
          <h1 data-aos="fade-up">- Wedding Gift -</h1>
          <p data-aos="fade-up">Tanpa mengurangi rasa hormat,</p>
          <p data-aos="fade-up">Bagi anda yang ingin memberikan tanda kasih</p>
          <p data-aos="fade-up">Untuk kedua mempelai, dapat melalui E-Wallet</p>
          {/* <div className='section-card' data-aos="fade-up">
            <img src={BRI} alt="logo-bri" width={100} />
            <div className='border'></div>
            {copy ? <p>COPY SUCCESS</p> :
              <p onClick={() => copyText('005801128097502')}><span><ContentCopyIcon fontSize='small' /></span> 005801128097502 </p>
            }
            <p>RAEZA LUVIANA</p>
          </div> */}
          <div className='section-card' data-aos="fade-up">
            <img src={bca} alt="logo-bri" width={100} />
            <div className='border'></div>
            {copy2 ? <p>COPY SUCCESS</p> :
              <p onClick={() => copyText('8115381471')}><span><ContentCopyIcon fontSize='small' /></span> 8115381471 </p>
            }
            <p>EKA NOVIASARI</p>
          </div>
        </div>
        <div className='container-wish'>
          <h1 data-aos="fade-up">- Wedding Wish -</h1>
          <form onSubmit={(e) => handleSubmit(e)}>
            <TextField value={name} style={{ backgroundColor: 'white' }} required onChange={(e) => setName(e.target.value)} id="outlined-basic" label="Nama" variant="outlined" fullWidth />
            <textarea value={text} required onChange={(e) => setText(e.target.value)} placeholder='Pesan Anda ...'></textarea>
            <button type='submit'>Kirim Ucapan</button>
          </form>
          <div className='container-message' data-aos="fade-up">
            {data.map((el, i) =>
              <div className='profile' key={i}>
                <div className='img-profile'>
                  <p>{el.name[0].toUpperCase()}</p>
                </div>
                <div className='msg'>
                  <div className='arrow-left'></div>
                  <p style={{ fontWeight: 700, paddingBottom: '5px' }}>{el.name}</p>
                  <p>{el.message}</p>
                  <p style={{ textAlign: 'right', paddingTop: '30px' }}>{handleDate(el.createdAt)}</p>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='container-ending'>
          <p data-aos="fade-up">Hormat Kami,</p>
          <h1 data-aos="fade-up">Eka & Lukman</h1>
        </div>
      </div>

    </main>
  )
}

export default App
